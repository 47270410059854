<template>
  <v-container>
    <v-row>
      <h4 class="title">List of operating cities</h4>
    </v-row>
    <v-btn fab small color="accent" to="edit/">
      <v-icon>add</v-icon>
    </v-btn>
    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="cities" :items-per-page="15">
          <template v-slot:[`item.id`]="{ item }">
            <router-link :to="'edit/' + item.id">
              {{ item.city_name }}</router-link
            >
          </template>
          <template v-slot:[`item.createdTime`]="{ item }">
            {{ item.createdTime | formatDateTime }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      headers: [
        { text: 'City', value: 'id' },
        { text: 'Visible', value: 'isVisible' },
        { text: 'Count', value: 'producerCount' }
      ],
      cities: [],
      city: null
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      operatingCities: 'operatingCities'
    })
  },
  mounted() {
    this.$store.dispatch('getOperatingCities').then(() => {
      // gets city names from cities object
      this.cities = this.operatingCities
    })
  },
  methods: {}
}
</script>
